<template>
  <!-- 附件上传 -->
  <div class="accessory-upload">
    <span v-if="title != '' && !showUploadBtn" class="marR15">{{ title }}</span>
    <el-upload
      :class="[{ hide: noneBtnImg }]"
      :before-upload="handleBeforeUpload"
      :on-success="handleUploadSuccess"
      :action="uploadFile"
      :on-error="handleUploadError"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      show-file-list
      multiple
      :limit="limit"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :disabled="disabled"
      :on-change="imgChange"
      :list-type="listType"
      ref="upload"
    >
      <el-button
        v-if="listType != 'picture-card'"
        size="mini"
        type="primary"
        class="btn"
        :disabled="disabled"
        >点击上传
      </el-button>
      <i slot="default" v-else class="el-icon-plus"></i>
      <span
        slot="tip"
        class="el-upload__tip tip"
        v-if="['text', 'img'].includes(listType) && !showUploadBtn"
      >
        <!-- v-if="listType == 'text' && !showUploadBtn" -->
        (单个文件最大支持20M,支持格式PDF、Word、Excel、Txt、JPG、PNG、RAR、ZIP)
      </span>
      <div
        slot="tip"
        class="el-upload__tip tip"
        v-if="!isLimitMany && listType == 'picture-card'"
      >
        {{ `最多上传${limit}张,${explain}${otherTip}` }}
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { delOss, downloadOss } from "@/api/ossfile"; //阿里云接口
import { haveContent } from "@/utils";
export default {
  name: "AccessoryUpload",
  props: {
    // 上传按钮前标题
    title: {
      type: String,
      default: "附件上传：",
    },
    //是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    //不加限制图片张数
    isLimitMany: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 10,
    },
    // 上传组件样式：查看elementUI
    listType: {
      type: String,
      default: "text", // 'picture-card'
    },
    fileList: {
      type: Array,
      default: () => [],
    },
    //只需要展示上传按钮
    showUploadBtn: {
      type: Boolean,
      default: false,
    },
    explain: {
      type: String,
      default: "仅支持格式JPG、PNG、JPEG",
    },
    widthHeight: {
      type: Array,
      default: () => [],
    },
    // 是否隐藏上传按钮
    noneBtnImg: {
      type: Boolean,
      default: false,
    },
    fileSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      //上传文件类型
      fileType: [
        "pdf",
        "doc",
        "xls",
        "txt",
        "jpg",
        "png",
        "rar",
        "zip",
        "exe",
        "gif",
        "jpeg",
      ],
      //上传文件的大小(MB)
      // 上传的服务器地址
      uploadFile: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload",
      fileListCom: [],
      // 图片预览
      dialogImageUrl: "",
      dialogVisible: false,
      // noneBtnImg: false, // 图片数量达标时隐藏上传按钮
      isHideNum: 0,
    };
  },
  watch: {
    fileList(newVal) {
      // console.log('文件数量-0-0-0-0-0：', typeof this.limit, newVal.length)
      this.isHideNum = newVal?.length;
    },
    isHideNum(nVal) {
      console.log("图片数量========", nVal);
    },
  },
  computed: {
    otherTip() {
      let t = " ";
      const [width, height] = this.widthHeight;
      if (haveContent(width) || haveContent(height)) {
        let maxW = Number(String(width).replace(/\D/g, ""));
        maxW = Number.isFinite(maxW) ? maxW : 0;
        if (maxW > 0) {
          t = `${t}${t ? "，" : ""}宽不能超过 ${maxW}px`;
        }
        let maxH = Number(String(height).replace(/\D/g, ""));
        maxH = Number.isFinite(maxH) ? maxH : 0;
        if (maxH > 0) {
          t = `${t}${t ? "，" : ""}高不能超过 ${maxH}px`;
        }
      }
      return t;
    },
  },
  methods: {
    //上传附件的方法
    // 文件上传前校检格式和大小
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        // 校检文件类型
        if (this.fileType) {
          let fileExtension = "";
          if (file.name.lastIndexOf(".") > -1) {
            fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
          }
          const isTypeOk = this.fileType.some(
            (type) =>
              file.type.indexOf(type.toLowerCase()) > -1 ||
              (fileExtension && fileExtension.indexOf(type.toLowerCase()) > -1)
          );
          if (!isTypeOk) {
            this.$modal.msgError(
              `文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`
            );
            return reject(new Error("Image size too large 1"));
          }
        }
        // 校检文件上传大小
        if (this.fileSize) {
          const isLt = file.size / 1024 / 1024 < this.fileSize;
          if (!isLt) {
            this.$modal.msgError(`上传文件大小不能超过 ${this.fileSize} MB!`);
            return reject(new Error("Image size too large 2"));
          }
        }
        if (this.widthHeight?.length) {
          const [maxW, maxH] = this.widthHeight;
          const img = new Image();
          const blobUrl = URL.createObjectURL(file);
          img.src = blobUrl;
          img.onload = () => {
            if (img.width > maxW) {
              this.$message.error(`文件宽不能超过 ${maxW}px`);
              reject(new Error("Image size too large 3"));
            } else if (img.height > maxH) {
              this.$message.error(`文件高不能超过 ${maxH}px`);
              reject(new Error("Image size too large 3"));
            } else {
              URL.revokeObjectURL(blobUrl);
              this.$modal.loading("正在上传文件，请稍候...");
              resolve(true);
            }
          };
          img.onerror = () => {
            this.$message.error("图片加载失败");
            reject(new Error("Failed to load image"));
          };
        } else {
          this.$modal.loading("正在上传文件，请稍候...");
          resolve(true);
        }
      });
    },
    // 文件上传个数超出的回调
    handleExceed() {
      this.$modal.msgError(`上传文件数量不能超过 ${this.limit} 个!`);
    },
    // 文件上传成功回调
    handleUploadSuccess(res) {
      if (this.showUploadBtn) this.$refs.upload.clearFiles(); //去掉文件列表

      console.log("上传：succes", res.data);
      res.data.imageUrl = res.data.accessUrl;
      this.$emit("getFileItems", res.data);
      let { data } = res;
      let temp = 1;
      this.fileListCom.push({
        name: data.fileName,
        url: data.accessUrl,
        domain: data.domain,
        filePath: data.filePath,
        fileSize: data.fileSize,
        uid: new Date().getTime() + temp++,
      });
      this.$modal.closeLoading();
      // console.log("上传数量改变111", this.isHideNum)
      // this.isHideNum++
      // console.log("上传数量改变222", this.isHideNum)
    },
    // 上传改变事件
    imgChange(file, fileList) {
      // this.noneBtnImg = fileList.length >= this.limit
    },
    // 文件上传失败的回调
    handleUploadError() {
      this.$modal.msgError("上传失败，请重试");
      this.$modal.closeLoading();
      if (this.showUploadBtn) this.$refs.upload.clearFiles(); //去掉文件列表
    },
    //已上传的文件 进行删除
    handleRemove(file) {
      console.log("file1", file.uid);
      if (this.listType == "text") {
        let filePath;
        if (this.listType) {
          filePath = file.filePath;
        } else {
          filePath = file.filePath;
        }
        delOss(file.url).then(() => {
          console.log(file.url, "file.url");
          this.fileListCom = this.fileListCom.filter((item) => {
            console.log("删除图片1----", this.fileListCom);

            return false;
          });
          this.$emit("delFileItems", file.url);
        });
      } else {
        console.log("删除数量改变111", this.isHideNum);
        this.isHideNum--;
        console.log("删除数量改变222", file);
        const imgUrl = file.response ? file.response.data.accessUrl : file.url;
        console.log("imgUrl", imgUrl);
        this.$emit("delFileItems", file, imgUrl);
      }
    },
    //删除附件的回调
    beforeRemove(file) {},
    //点击文件列表中已上传文件进行下载
    handlePreview(file) {
      console.log(file, "file", this.listType);
      if (this.listType == "img") {
        let a = document.createElement("a");
        a.href = file.accessUrl;
        a.download = file.fileName;
        // file.name
        document.body.appendChild(a);
        a.click();
        // document.body.appendChild(a);
        return;
      }
      if (this.listType == "text") {
        downloadOss(file.filePath).then((res) => {
          let blob = new Blob([res]);
          // 获取heads中的filename文件名
          let downloadElement = document.createElement("a");
          // 创建下载的链接
          let href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          // 下载后文件名
          downloadElement.download = file.name;
          document.body.appendChild(downloadElement);
          // 点击下载
          downloadElement.click();
          // 下载完成移除元素
          document.body.removeChild(downloadElement);
          // 释放掉blob对象
          window.URL.revokeObjectURL(href);
        });
      } else if (this.listType == "picture-card") {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accessory-upload {
  display: flex;
  //width: 50%;
  .tip {
    line-height: 20px;
    color: #c0c4cc;
  }

  .btn {
    margin: 0 5px;
  }
}

::v-deep .el-upload--picture-card {
  height: 80px;
  line-height: 86px;
  width: 80px;
}

::v-deep .el-upload-list__item-actions {
  height: 80px;
  width: 80px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
  height: 80px;
  width: 80px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  height: 80px;
  width: 80px;
}

.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
.hide {
  ::v-deep .el-upload--picture-card {
    display: none !important;
  }
}
</style>
